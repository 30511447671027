import find from 'lodash/find'
import get from 'lodash/get'

export const useSettingsStore = defineStore({
  id: 'settings-store',
  state: () => {
    return {
      data: [],
      projectsData: []
    }
  },
  actions: {
    async fetchSettings() {
      if (this.data.length) {
        return Promise.resolve(this.data)
      }
      // const params = { page: 1, itemsPerPage: 9999 }
      // const options = {
      //   onSuccess: response => {
      //     this.data = get(response, 'hydra:member', [])
      //   }
      // }
      // return useApi().settings.getAll(params, options)
      const { data: response } = await useFetch('/api/settings')
      // console.log('/api/settings', response.value)
      this.data = get(response.value, 'hydra:member', [])
      return this.data
    },
    async fetchProjectsSettings() {
      if (this.projectsData.length) {
        return Promise.resolve(this.projectsData)
      }
      // const params = {
      //   type: 'projects',
      //   itemsPerPage: 9999
      // }
      const { data: response } = await useFetch('/api/project-settings')
      this.projectsData = get(response.value, 'hydra:member', [])
      return this.projectsData
    }
  },
  getters: {
    currentChannelSettings: state => {
      const currentChannelCode = useStoreType().isHunting
        ? SETTINGS.WEAPON_CHANNEL_CODE
        : SETTINGS.FISHING_CHANNEL_CODE
      return state.data.filter(
        el =>
          el.channel?.code === currentChannelCode &&
          !el.propertyName?.startsWith(SETTINGS.EXTRA_PREFIX)
      )
    },

    socials: state =>
      state.currentChannelSettings.filter(
        el => el.group === SETTINGS.SOCIAL_NETWORKS_GROUP
      ),
    phoneNumbers: state =>
      state.currentChannelSettings.filter(
        el =>
          el.propertyName?.startsWith(SETTINGS.PHONE_PREFIX) &&
          el.propertyName !== SETTINGS.PHONE_HOTLINE
      ),
    weaponPhoneNumbers: state =>
      state.data.filter(
        el =>
          el.channel?.code === SETTINGS.WEAPON_CHANNEL_CODE &&
          el.propertyName?.startsWith(SETTINGS.PHONE_PREFIX) &&
          el.propertyName !== SETTINGS.PHONE_HOTLINE
      ),
    fishingPhoneNumbers: state =>
      state.data.filter(
        el =>
          el.channel?.code === SETTINGS.FISHING_CHANNEL_CODE &&
          el.propertyName?.startsWith(SETTINGS.PHONE_PREFIX) &&
          el.propertyName !== SETTINGS.PHONE_HOTLINE
      ),
    messengers: state =>
      state.currentChannelSettings.filter(el =>
        el.propertyName?.startsWith(SETTINGS.MESSENGER_PREFIX)
      ),
    openingHours: state =>
      find(
        state.currentChannelSettings,
        el => el.propertyName === SETTINGS.OPENING_HOURS
      )?.propertyValue || '',
    hotlinePhoneNumber: state =>
      find(
        state.currentChannelSettings,
        el => el.propertyName === SETTINGS.PHONE_HOTLINE
      )?.propertyValue || SETTINGS.DEFAULT_HOTLINE_PHONE,
    email: state =>
      find(
        state.currentChannelSettings,
        el => el.propertyName === SETTINGS.EMAIL
      )?.propertyValue || '',

    commissionWeaponContacts: state =>
      state.data.filter(
        el =>
          el.channel?.code === SETTINGS.WEAPON_CHANNEL_CODE &&
          el.propertyName?.startsWith(SETTINGS.COMMISSION_PREFIX) &&
          el.propertyName !== SETTINGS.PHONE_HOTLINE
      ),
    mainOfficeContacts: state =>
      state.data.filter(
        el =>
          el.channel?.code === SETTINGS.WEAPON_CHANNEL_CODE &&
          el.propertyName?.startsWith(SETTINGS.MAIN_OFFICE) &&
          el.propertyName !== SETTINGS.PHONE_HOTLINE
      ),
    weaponContacts: state =>
      state.data.filter(
        el =>
          el.channel?.code === SETTINGS.WEAPON_CHANNEL_CODE &&
          !el.propertyName?.startsWith(SETTINGS.COMMISSION_PREFIX) &&
          !el.propertyName?.startsWith(SETTINGS.MAIN_OFFICE) &&
          el.propertyName !== SETTINGS.PHONE_HOTLINE &&
          el.group === SETTINGS.CONTACTS_GROUP
      ),
    fishingContacts: state =>
      state.data.filter(
        el =>
          el.channel?.code === SETTINGS.FISHING_CHANNEL_CODE &&
          el.propertyName !== SETTINGS.PHONE_HOTLINE &&
          !el.propertyName?.startsWith(SETTINGS.MAIN_OFFICE) &&
          el.group === SETTINGS.CONTACTS_GROUP
      ),
    allProjectsSettings: state => state.projectsData
  }
})
